import { createRoot } from 'react-dom/client'
import { motion, Variant } from 'framer-motion'
import { useWindowScroll } from 'react-use'
import ExampleDynamicLoading from './components/Gallery'
import LogoPng from './assets/logo.png'
import images from './data/images.json'
import './style.scss'

const container = document.getElementById('root')
const root = createRoot(container!)

const App = () => {

  const animateLogo: Variant = { top: '-8em', rotate: '50deg', transition: { ease: 'backIn', duration: 0.5 }, transitionEnd: { display: 'none' } }

  const {y} = useWindowScroll()

  const dumpLogo = y > 5

  return (
    <div className="Site">
      <motion.img
        className="Logo"
        src={LogoPng}
        style={{ display: dumpLogo ? undefined : 'block'}}
        animate={dumpLogo ? animateLogo : { rotate: '15deg', top: '0.75em', transition: { delay: 0.25 } }}
        initial={{ rotate: '15deg' }}
      />
      <ExampleDynamicLoading photos={images}/>
    </div>
  )
}

root.render(<App/>)