import React, { useState, useEffect, useCallback } from 'react'
import Gallery from 'react-photo-gallery'
import { debounce } from '../constants/utils'
import Lightbox from './Lightbox'
import { AnimatePresence } from 'framer-motion'

const MAX_IMAGES_LOAD = 14

function ExampleDynamicLoading({ photos }: { photos: { src: string, width: number, height: number}[] }) {
  const [images, setImages] = useState(photos.slice(0, MAX_IMAGES_LOAD))
  const [pageNum, setPageNum] = useState(1)
  const [lightboxImageIndex, setLightboxImageIndex] = useState(0)
  const [lightboxIsOpen, setlightboxIsOpen] = useState(false)

  const openLightbox = useCallback((event: any, { photo, index }: { photo: any, index: number }) => {
    setLightboxImageIndex(index)
    setlightboxIsOpen(true)
  }, [])

  const closeLightbox = () => {
    setlightboxIsOpen(false)
  }

  const finished = images.length >= photos.length

  const loadMorePhotos = debounce(() => {
    if (finished) return

    setImages(images.concat(photos.slice(images.length, images.length + MAX_IMAGES_LOAD)))
    setPageNum(pageNum + 1)
  }, 200)

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  })

  const handleScroll = () => {
    let scrollY = window.scrollY || window.pageYOffset || document.documentElement.scrollTop

    const loadBeforeScrollY = document.body.offsetHeight - (window.innerHeight * 0.05)
    if (window.innerHeight + scrollY >= loadBeforeScrollY) {
      loadMorePhotos()
    }
  }

  return (
    <div className="Gallery">
      <Gallery photos={images} direction={"row"} onClick={openLightbox} targetRowHeight={450} margin={5}/>
      {/* @ts-ignore */}
      <AnimatePresence>
        {lightboxIsOpen ? (
          <Lightbox images={images.map(i => i.src)} startingIndex={lightboxImageIndex} onClose={closeLightbox}/>
        ) : null}
      </AnimatePresence>
      { finished && (
        <p>Fin</p>
      )}
    </div>
  )
}

export default ExampleDynamicLoading