import { useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { wrap } from 'popmotion'
import { useKeyPressEvent } from 'react-use'

const variants = {
  enter: (direction: {x : number, y: number}) => {
    return {
      x: direction.x > 0 ? 1000 : -1000,
      y: direction.y ? direction.y > 0 ? 1000 : -1000 : 0,
      opacity: 0
    }
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1
  },
  exit: (direction: {x : number, y: number}) => {
    console.log('exit', direction.y ? direction.y > 0 ? -1000 : 1000 : 0)
    return {
      zIndex: 0,
      x: !direction.y ? direction.x < 0 ? 1000 : -1000: 0,
      y: direction.y ? direction.y > 0 ? 1000 : -1000 : 0,
      opacity: 0
    }
  }
}

/**
 * Experimenting with distilling swipe offset and velocity into a single variable, so the
 * less distance a user has swiped, the more velocity they need to register as a swipe.
 * Should accomodate longer swipes and short flicks without having binary checks on
 * just distance thresholds and velocity > 0.
 */
const swipeConfidenceThresholdX = 10000
const swipeConfidenceThresholdY = 10000
const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity
}

const Lightbox = ({ images, startingIndex, onClose }: { images: string[], startingIndex: number, onClose: () => void }) => {
  const [[page, direction], setPage] = useState([startingIndex, {x: 0, y: 0}])
  // We only have 3 images, but we paginate them absolutely (ie 1, 2, 3, 4, 5...) and
  // then wrap that within 0-2 to find our image ID in the array below. By passing an
  // absolute page index as the `motion` component's `key` prop, `AnimatePresence` will
  // detect it as an entirely new image. So you can infinitely paginate as few as 1 images.
  const imageIndex = wrap(0, images.length, page)

  const paginate = (newDirection: number) => {
    setPage([page + newDirection, {x: newDirection, y: 0}])
  }

  const close = (newDirection: number) => {
    setPage([-1, {x: 0, y: newDirection}])
    onClose()
  }

  useKeyPressEvent('Escape', () => close(1))
  useKeyPressEvent('ArrowRight', () => paginate(1))
  useKeyPressEvent('ArrowLeft', () => paginate(-1))

  function getContainedSize (img: HTMLImageElement): { width: number, height: number } {

    const ratio = img.naturalWidth/img.naturalHeight
    let width = img.height * ratio
    let height = img.height

    if (width > img.width) {
      width = img.width
      height = img.width/ratio
    }

    return { width, height }

  }

  const onMouseDown = (event: React.MouseEvent<HTMLImageElement>) => {
    const { clientX, clientY, currentTarget } = event
    const { width: imgWidth, height: imgHeight } = getContainedSize(currentTarget)
    // get the current viewport dimensions
    if (currentTarget.parentNode) {
      const { width: viewportWidth, height: viewportHeight } = (currentTarget.parentNode as HTMLDivElement).getBoundingClientRect()
      const xMargin = (viewportWidth - imgWidth) / 2
      const yMargin = (viewportHeight - imgHeight) / 2
      const touchedXMargin = clientX < xMargin || clientX > (xMargin + imgWidth)
      const touchedYMargin = clientY < yMargin || clientY > (yMargin + imgHeight)
      if (touchedXMargin || touchedYMargin) {
        onClose()
      }
    }
  }

  return (
    <motion.div className="Lightbox" initial={{ opacity: 0 }} animate={{ opacity: 1}} exit={{ opacity: 0 }}>
      <AnimatePresence initial={false} custom={direction}>
        <motion.img
          whileTap={{ scale: 0.9 }}
          onMouseDown={onMouseDown}
          key={page}
          src={images[imageIndex]}
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: { type: "spring", stiffness: 300, damping: 30 },
            opacity: { duration: 0.2 }
          }}
          drag
          dragConstraints={{ left: 0, right: 0, top: direction.y ? undefined : 0, bottom: direction.y ? undefined : 0 }}
          dragElastic={1}
          onDragEnd={(e, { offset, velocity }) => {
            const swipeX = swipePower(offset.x, velocity.x)
            const swipeY = swipePower(offset.y, velocity.y)

            if (swipeX < -swipeConfidenceThresholdX) {

              paginate(1)

            } else if (swipeX > swipeConfidenceThresholdX) {

              paginate(-1)

            } else {

              if (swipeY < -swipeConfidenceThresholdY || swipeY > swipeConfidenceThresholdY) {

                close(swipeY)
              }
            }
          }}
        />
      </AnimatePresence>
    </motion.div>
  )
}

export default Lightbox